@import "styles/_variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  left: 10vw;
  right: 10vw;
  min-height: 60px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 adjust-color($appbar-bg-color-1, $alpha: -0.25);
  padding: 12px 16px 12px 16px;
  border-radius: 10px 10px 0 0;
}

.summary {
  font-size: 14px;
  font-weight: 600;
  margin-right: 8px;
}

.view svg {
  margin-top: 1px;
}

.view {
  composes: button primary border from global;
  padding-right: 20px;
  text-decoration: none;
}

.clearSelection {
  composes: smaller-button danger-invert from global;
  padding: 4px;
}

.selectAll {
  composes: smaller-button secondary from global;
  padding: 4px;
}

.close {
  composes: button secondary from global;
  padding: 0;
}