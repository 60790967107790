@import "styles/_variables.scss";
.main {
  flex: 1;
}

.parameters {
  background-color: adjust-color($color-1, $alpha: -0.4);
  color: $bg-color-1;
  min-width: 380px;
  max-width: 380px;
  padding: 16px;
  min-height: calc(100vh - 56px);
  transform: translateX(0px) scaleX(1);
  transition: all ease 250ms;
  opacity: 1;
}

.parameters h3 {
  border-bottom: 1px solid $bg-color-1;
  padding-bottom: 8px;
  font-size: 16px;
}

.back {
  composes: clickable from global;
  position: absolute;
  right: 400px;
  top: 80px;
  color: $primary-color;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: $primary-bg-color;
  border-radius: 20px;
}

.download {
  composes: clickable from global;
  position: absolute;
  right: 400px;
  top: 140px;
  color: $primary-color;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: $primary-bg-color;
  border-radius: 20px;
}

.downloadQR {
  composes: clickable from global;
  position: absolute;
  right: 400px;
  top: 200px;
  color: $primary-color;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: $primary-bg-color;
  border-radius: 20px;
}

.downloadQR svg {
  width: 20px;
  height: 20px;
}

.review {
  composes: clickable primary from global;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 100px;
  right: -41px;
}

.scroll {
  position: relative;
  max-width: calc(100% + 32px);
  margin: 8px 0 8px 0;
}

.photo {
  min-height: calc(100vh - 56px - 135px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo > svg {
  box-shadow: 0 0 5px 0 #999;
}

.thumbs {
  height: 135px;
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.thumbs h4 {
  font-weight: 500;
  text-transform: uppercase;
}

.files {
  display: flex;
  max-width: calc(100vw - 560px);
  padding: 4px 4px 16px 4px;
  overflow-x: overlay;
  overflow-y: hidden;
}

.moreFiles {
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-width: 60px;
  max-width: 60px;
  height: 90px;
  box-shadow: 0 0 5px 0 #999;
  color: #999;
  border-radius: 4px;
  pointer-events: none;
}

.moreFiles label {
  background-color: #999;
  color: #fff;
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 10px;
}

.moreFiles svg{
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  margin: 8px 4px;
}