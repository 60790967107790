.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.root h3 {
  margin-bottom: 16px;
}

.loginButton {
  composes: clickable from global;
  display: block;
  background: white;
  color: #444;
  width: 200px;
  border-radius: 5px;
  border: thin solid #ddd;
  box-shadow: 0 0 5px grey;
  white-space: nowrap;
}

.loginButton:hover {
  cursor: pointer;
}

.loginButton span.icon {
  background: url('./g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

.loginButton span.buttonText {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}