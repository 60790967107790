@import "styles/_variables.scss";

.root {
  position: fixed;
  top: 80px;
  left: calc(50vw - 125px);
  background-color: #fff;
  border: 1px solid $appbar-bg-color-1;
  text-align: center;
  padding: 0 0 0 12px;
  border-radius: 4px;
  z-index: 90;
}

.refresh {
  composes: clickable from global;
  margin-left: 4px;
  font-size: 12px;
  background-color: $primary-bg-color;
  color: $primary-color;
  padding: 4px 8px;
}
