@import "styles/_variables.scss";

.emptyState {
  margin-top: 20vh;
  text-align: center;
}

.emptyState > h3 {
  margin-bottom: 16px;
}

.emptyState lottie-player {
  height: 200px;
  text-align: center;
}

.back {
  composes: secondary button border from global;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.back svg {
  height: 12px;
  margin-left: 0;
}

.close {
  composes: error-invert border button border from global;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close svg {
  height: 12px;
  margin-left: 0;
}

.buttons {
  display: inline-flex;
}

.buttons button:not(:last-child) {
  margin-right: 4px;
}