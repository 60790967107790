.root {
  width: 420px;
  margin: -20px calc(50vw - 420px / 2) 40px calc(50vw - 420px / 2);
}

.root lottie-player {
  width: 420px;
  height: 420px;
  transform: translateY(-150px);
}

.intro h2 {
  margin-bottom: 8px;
}

.intro {
  display: block;
  text-align: center;
  width: 400px;
  padding: 0 10px;
  margin-top: -280px;
  margin-bottom: 28px;
}

.help {
  margin-top: 4px;
  line-height: 16px;
  color: #999;
  text-align: justify;
}
