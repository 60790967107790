.folder label {
  position: absolute;
  background-color: #00000080;
  max-width: 120px;
  font-size: 12px;
  bottom: 8px;
  padding: 4px 16px;
  border-radius: 12px;
  color: #fff;
  pointer-events: none;
}

.folder {
  composes: clickable from global;
  background-color: #e9e9e9;
  background-image: url("../../assets/no-image.jpg");
  background-size: 150%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 120px;
  height: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 20px 0 0;
  font-size: 14px;
  padding: 12px 8px;
  border-radius: 10px;
  cursor: pointer;
}

.folder svg {
  width: 32px;
  border-radius: 50%;
  position: absolute;
  top: -14px;
  left: calc(50% - 16px);
  background-color: #4481eb;
  border: 5px solid #4481eb;
}