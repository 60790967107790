.backdrop {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #00000060;
  z-index: 999;
}

.modal {
  background-color: #fff;
  padding: 16px 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 #888;
  z-index: 999;
}
