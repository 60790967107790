.primary {
  display: inline-block;
  background-color: $primary-bg-color;
  color: $primary-color;
  border-color: $primary-bg-color;
}

.primary-invert {
  display: inline-block;
  background-color: transparent;
  color: $primary-bg-color;
}

.secondary {
  display: inline-block;
  background-color: transparent;
  color: $color-1;
  border-color: transparent;
}

.secondary-invert {
  display: inline-block;
  background-color: transparent;
  color: $bg-color-1;
  border-color: transparent;
}

.error {
  color: #fff;
  background-color: #d3362d;
}

.error-invert {
  color: #d3362d;
  background-color: #fff;
}

.danger {
  color: #fff;
  background-color: #d3362d;
}

.danger-invert {
  color: #d3362d;
  border-color: transparent;
  background-color: transparent;
}
