$bg-color-1: #fff;
$color-1: #222;
$highlight-color: #4481eb;
$appbar-bg-color-1: #4481eb;
$appbar-color-1: #fff;
$primary-bg-color: #4481eb;
$primary-color: #fff;
$secondary-color: #ddd;
// $bg-color-1: #000;
// $color-1: #fff;
// $overlay-color-1: #fff;
