@import "styles/_variables.scss";

.download {
  display: block;
  width: 400px;
  background-color: $bg-color-1;
  color: $color-1;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-right: 16px;
  box-shadow: 0 0 5px 0 adjust-color($color-1, $alpha: -0.75);
}

.progress {
  width: 100%;
  height: 6px;
  background-color: #d0d0d0;
}

.progress :global(.success) {
  height: 6px;
  background-color: #6060ff;
  transition: all ease-in-out 1000ms;
}

.buttons {
  composes: buttons from global;
  margin-top: 6px;
}

.action {
  composes: button primary-invert from global;
  border: none;
  padding: 0;
  text-decoration: none;
}

.action svg {
  width: 16px;
  height: 16px;
}
