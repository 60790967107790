.root {
  margin-top: 20vh;
  margin-left: 32px;
}

.root h2 {
  margin-bottom: 8px;
}

.root span{
  display: block;
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}