.button {
  @extend .clickable;
  display: inline-flex;
  align-items: center;
  padding: 7px 16px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  text-decoration: none
}

.bigger-button {
  @extend .clickable;
  display: inline-flex;
  align-items: center;
  padding: 10px 18px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  text-decoration: none
}

.smaller-button {
  @extend .clickable;
  display: inline-flex;
  align-items: center;
  padding: 3px 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.mini-button {
  @extend .clickable;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 2px 6px 3px 6px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.button svg {
  height: 16px;
  margin: 0 4px 2px 4px;
}

.smaller-button svg {
  height: 12px;
  margin: 0 4px 2px 4px;
}

.buttons {
  display: flex;
  align-items: center;
}

.buttons > button:not(:last-child),
.buttons > a:not(:last-child) {
  margin-right: 8px;
}