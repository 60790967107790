@import "./variables";
$tab-bg-color: #f0f0f0;
$tab-active-color: $appbar-bg-color-1;

.tabs {
  display: flex;
  border-bottom: 2px solid $tab-bg-color;
}

.tabs .tab {
  @extend .clickable;
  margin-bottom: -1px;
  background-color: $tab-bg-color;
  min-width: 120px;
  padding: 8px 16px;
  font-weight: 500;
}

.tabs button.active {
  color: $tab-active-color;
  border-bottom: 2px solid $tab-active-color;
}
