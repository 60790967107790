@import "styles/_variables.scss";

.body {
  width: 100%;
  min-height: 100vh;
  padding: 72px 16px 16px 16px;
  border-radius: 8px 0 0 8px;
  margin: 0;
  background-color: #f5f5f5;
}

.section {
  padding: 0;
  border-radius: 8px;
}

.title {
  display: flex;
  align-items: center;
}

.title h2 {
  margin-right: 8px;
}

