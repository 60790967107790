@import "styles/_variables.scss";

.file {
  composes: clickable from global;
  position: relative;
  margin-right: 16px;
  margin-bottom: 4px;
}

.file > svg {
  box-shadow: 0 0 5px 0 #999;
}

.file:global(.selecting:not(.selected))::before {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000060;
}

.file img {
  box-shadow: 0 0 5px 0 adjust-color($color-1, $alpha: -0.666);
}

.file::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
}

.select {
  composes: clickable border from global;
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: rgba($primary-bg-color, $alpha: 0.25);
  border-color: $primary-bg-color;
}

.select:global(.selected) {
  background-color: $primary-bg-color;
  border-color: $primary-bg-color;
}

.download {
  composes: clickable from global;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  border: none;
  background-color: rgba($primary-bg-color, $alpha: 0.8);
  color: $primary-color;
  width: 100%;
  padding: 4px;
  transform: scaleY(0);
  transform-origin: center bottom;
  transition: all ease-in-out 100ms;
  text-decoration: none;
}

.file:hover .download {
  transform: scaleY(1);
}

.download svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
