.folder {
  composes: folder from "../Folders/Folder.module.css";
  background-image: url("./computer-laptop.svg") !important;
  background-size: 80px 80px !important;
  background-position: center 20px !important;
}

.openLocal {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #4481eb;
  color: #fff;
  padding: 16px 4px 12px 4px;
  font-size: 13px;
  border-radius: 0 0 8px 8px;
}