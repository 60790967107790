.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.root p {
  font-size: 14px;
  margin: 4px 0 12px 0;
}
