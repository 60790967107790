.root {
  composes: modal from global;
  width: 80vw;
  max-width: 800px;
  margin: calc(50vh - min(80vw, 500px) / 2) calc(50vw - min(80vw, 800px) / 2);
}

.body {
  height: 80vh;
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  overflow-y: overlay;
  margin: 0 -16px;
  padding: 12px 16px;
}

.body > div {
  cursor: pointer;
  margin: 0 12px 12px 0;
}
