@import "styles/_variables.scss";

.root {
  composes: modal from global;
  margin: calc(50vh - max(200px, 20vh) / 2) auto;
  max-width: 500px;
  width: 40vw;
  height: min-content;
}

.body {
  min-height: 200px;
  height: 20vh;
  padding-top: 4px;
}

.root h2 {
  box-shadow: 0 6px 3px -3px #f0f0f0;
  margin: 0 -16px;
  padding: 0 24px 8px 24px;
  margin-bottom: 12px;
}

.footer {
  composes: buttons from global;
  justify-content: flex-end;
}
