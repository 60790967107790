@import "styles/_variables.scss";

.theme {
  composes: clickable from global;
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin-top: 8px;
}

.theme > svg {
  box-shadow: 0 0 5px 0 #999;
}

.switchIcon {
  opacity: 0.75;
  pointer-events: none;
  position: absolute;
  left: 4px;
  bottom: 6px;
  width: 16px;
  height: 16px;
  text-align: center;
  background-color: adjust-color($appbar-bg-color-1, $alpha: -0.25);
  color: $appbar-color-1;
  border-radius: 15px;
}

.switchIcon svg {
  width: 10px;
  transform: translate(0.5px, 2px);
}