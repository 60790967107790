@import-normalize;

body {
  /* background-image: linear-gradient(to top, #4481eb 0%, #04befe 100%); */
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 4px 0;
}

h1 {
  font-size: 24px;
  font-weight: 400;
}

h2 {
  font-size: 20px;
  font-weight: 400;
}

h3 {
  font-size: 18px;
  font-weight: 400;
}

textarea {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  border-radius: 4px;
  padding: 6px;
  resize: vertical;
}

input,
select {
  border: 1px solid #4481eb;
  margin-bottom: 2px;
  color: #222;
  border-radius: 4px;
  padding: 6px;
  font-size: 12px;
  font-weight: 400;
  outline: none;
}

input:focus {
  box-shadow: 0 0 5px 0 #4481eb60;
}

.form-item {
  flex: 1;
  margin: 0 0 8px 0;
}

.form-item label {
  display: block;
  line-height: 20px;
  font-size: 12px;
  padding: 0;
  margin-bottom: 4px;
}

.form-item.required label::after {
  content: "*";
  padding-left: 2px;
  color: red;
}

.form-item .error {
  display: block;
  color: #990000;
  padding: 4px 0 0 0;
}

.flex-lr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./styles/AvenirNext-Regular.ttf");
}
@font-face {
  font-family: "Avenir";
  src: url("./styles/Avenir.ttf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("./styles/AvenirNext-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Avenir Next";
  src: url("./styles/AvenirNext-DemiBold.ttf");
  font-weight: 700;
}
