@import "styles/_variables.scss";

$height: 4px;

.root {
  padding: 16px;
}

.rule {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: $height;
  background-color: adjust-color($appbar-color-1, $alpha: -0.5);
  border-radius: $height/2;
}

.current {
  cursor: pointer;
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: $appbar-color-1;
  transform: translate(-5px + $height / 2, -5px + $height / 2);
  border-radius: 5px;
  box-shadow: 0 0 3px 1px $appbar-color-1;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex > :first-child {
  flex-grow: 1;
}

.flex > :last-child {
  width: 60px;
}
