@import "styles/_variables.scss";

.body {
  width: 100%;
  min-height: 100vh;
  padding: 64px 16px 16px 16px;
  border-radius: 8px 0 0 8px;
  margin: 0;
  background-color: #f5f5f5;
}

.section {
  padding: 16px 0;
  border-radius: 8px;
}

.title {
  display: flex;
  align-items: center;
}

.more {
  composes: button from global;
  border: 0;
  color: $primary-bg-color;
  background-color: transparent;
}

.more svg {
  width: 12px;
  height: 12px;
}
