.empty {
  flex: 1;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 56px);
}

.empty lottie-player {
  height: 200px;
}
