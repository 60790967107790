@import "styles/_variables.scss";

.more {
  position: relative;
  text-align: center;
  padding: 4px;
  width: 26px;
  height: 26px;
  border-radius: 15px;
  background-color: $appbar-bg-color-1;
  color: $appbar-color-1;
  cursor: pointer;
}

.more svg {
  width: 16px;
  height: 16px;
}

.menu {
  position: absolute;
  right: 0px;
  top: 35px;
  width: 200px;
  background-color: $appbar-bg-color-1;
  color: $appbar-color-1;
  padding: 8px 0 8px 0;
  text-align: left;
  border-radius: 8px;
  z-index: 99;
}

.menu h3 {
  text-align: center;
  padding: 0 16px 4px 16px;
  border-bottom: 1px solid adjust-color($appbar-color-1, $alpha: -0.5);
}

.menu ul {
  margin: 0;
  padding: 0 8px 0 16px;
  list-style: none;
}

.menu ul li {
  cursor: pointer;
  padding: 8px 4px 4px 4px;
  color: adjust-color($appbar-color-1, $alpha: -0.15);
}

.menu ul li:hover {
  color: $appbar-color-1;
}

.menu ul li:active {
  color: adjust-color($appbar-color-1, $alpha: -0.15);
}
