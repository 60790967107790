.clickable {
  opacity: 1;
  transition: all ease-in-out 250ms;
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.85;
}

.clickable:active {
  opacity: 0.5;
}