@import "./_variables";
@import "./reset.css";
@import "./global.scss";
@import "./clickable.scss";
@import "./color.scss";
@import "./border.scss";
@import "./padding.scss";
@import "./size.scss";
@import "./modal.css";
@import "./button.scss";
@import "./tabs.scss";

$color: red;

.breadcrumb {
  display: block;
  padding: 0;
  margin: 0;
  color: #444;
}

.breadcrumb li {
  display: inline;
}

.breadcrumb li:not(:last-child):after {
  display: inline-block;
  width: 12px;
  height: 12px;
  content: " ";
  background-image: url("./arrow-right.svg");
  margin: 0 6px;
}

.pull-r {
  justify-content: flex-end;
  text-align: right;
}