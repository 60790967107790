@import "styles/_variables.scss";

.root {
  display: flex;
  flex-direction: column;
  position: fixed;
  text-align: left;
  right: 0;
  top: 0;
  background-color: $bg-color-1;
  height: 100vh;
  width: 800px;
  margin-top: 0px;
  box-shadow: -2px 0 5px 1px #ffffff20;
  z-index: 9999;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  min-height: 65px;
}

.files {
  padding: 0 16px 0 0;
  overflow-y: overlay;
}

.file {
  padding: 8px 8px 0px 24px;

}

.file small {
  padding: 4px 0;
  display: block;
  font-size: 14px;
}

.uploadState {
  float: right;
  padding-right: 16px;
}

.remaining {
  composes: mini-button secondary border from global;
}

.processing {
  composes: mini-button secondary border from global;
  background-color: #ffff66;
}

.success {
  composes: mini-button primary border from global;
}

.duplicated {
  composes: mini-button error from global;
  background-color: #ff6666;
}

.error {
  composes: mini-button error from global;
}

.progress {
  background-color: #ddd;
  height: 2px;
  width: 100%;
  margin-top: 4px;
}

.progress > div {
  background-color: $appbar-bg-color-1;
  height: 2px;
  width: 0%;
}

.tool {
  padding: 4px 16px;
}

.floatButton {
  cursor: pointer;
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  z-index: 99;
  background-color: $appbar-bg-color-1;
  color: $appbar-color-1;
  padding: 12px 15px 12px 12px;
  border-radius: 8px;
}

.floatButton svg {
  width: 32px;
  height: 32px;
}

.floatButton:global(.ring) svg {
  animation: scale-up-center 250ms ease-in-out infinite both;
}

@keyframes scale-up-center {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.label {
  position: absolute;
  background-color: #990000;
  top: 6px;
  right: 5px;
  font-size: 10px;
  padding: 3px;
  border-radius: 16px;
  min-width: 17px;
}

.pendingEmpty {
  padding: 32px 32px;
  text-align: center;
}