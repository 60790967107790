.body {
  padding-top: 56px;
  width: 100%;
  min-height: calc(100vh - 56px);
  padding: 72px 16px 16px 16px;
  border-radius: 8px 0 0 8px;
  margin: 0;
}

.section {
  padding: 0;
  border-radius: 8px;
}

.back {
  composes: secondary button border from global;
}

.back svg {
  height: 12px;
}

.emptyState {
  margin-top: 20vh;
  text-align: center;
}

.emptyState h3 {
  margin-bottom: 16px;
}