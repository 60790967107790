.emptyState {
  margin-top: 20vh;
  text-align: center;
}

.emptyState > h3 {
  margin-bottom: 16px;
}

.emptyState lottie-player {
  height: 200px;
}

.back {
  composes: secondary button border from global;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.back svg {
  height: 12px;
  margin-left: 0;
}
