@import "styles/_variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: $appbar-bg-color-1;
  box-shadow: 0 2px 5px 0 adjust-color($appbar-bg-color-1, $alpha: -0.5);
  height: 56px;
  z-index: 99;
}

.logo {
  padding: 0 16px;
  box-sizing: border-box;
  transition: all ease-in 250ms;
}

.logo svg {
  height: 40px;
}

.logo:active {
  transform: scale(0.9);
}

.logo path {
  transition: all ease-in 250ms;
}

.logo:hover path:nth-child(1) {
  transform: translateX(-132px);
  transform-origin: center center;
}

.logo:hover path:nth-child(2) {
  transform: translateX(32px);
  transform-origin: center center;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px;
  z-index: 99;
}

.right button svg {
  width: 16px;
  height: 16px;
}

.search {
  display: inline-flex;
  background-color: adjust-color($bg-color-1, $alpha: -0.8);
  color: adjust-color($appbar-color-1, $alpha: -0.33);
  border: none;
  border-radius: 25px;
  width: 250px;
  font-size: 15px;
  padding: 12px 20px 10px 20px;
  cursor: text;
}

.search input {
  width: 100%;
  background-color: transparent;
  border: transparent;
  outline: none;
  padding: 0;
  color: #fff;
}

.search:hover {
  color: adjust-color($appbar-color-1, $alpha: -0.15);
}

.search svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.theme {
  border: none;
  background-color: transparent;
  color: $appbar-color-1;
  margin-right: 8px;
  text-align-last: right;
}

.selected {
  composes: clickable from global;
  background-color: transparent;
  color: #fff;
  width: 48px;
  height: 48px;
  position: relative;
  padding: 4px;
  margin-right: 8px;
}

.root .selected svg {
  width: 24px;
  height: 24px;
}

.selected label {
  display: block;
  position: absolute;
  background-color: #dd0000;
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 8px;
  right: 0;
  top: 2px;
}

.profile {
  margin-left: 8px;
}

.profile img {
  width: 32px;
  height: 32px;
  border-radius: 20px;
}

.logout {
  display: flex;
  align-items: center;
  text-align: left;
  composes: clickable from global;
  background-color: transparent;
  color: #fff;
}

.logout img {
  margin-right: 8px;
}

.logout small {
  color: #ddd;
}

.logout h4 {
  margin-bottom: 0;
  font-weight: 500;
}
