.root {
  composes: modal from global;
  margin: 10vh 10vw;
  max-width: 80vw;
  width: 80vw;
  height: 80vh;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.content {
  margin: 8px -16px 0 -16px;
  max-height: calc(80vh - 55px);
  padding: 8px 8px 8px 16px;
  overflow-y: scroll;
}

.contentEmpty {
  margin-top: 32vh;
  text-align: center;
}

.loading {
  transform: translateY(-200px);
  height: 200px;
}

.file {
  margin-bottom: 8px;
}

.row {
  display: flex;
  width: 100%;
}

.row > img {
  margin-right: 16px;
}

.row > div:first-child {
  min-width: 120px;
}

.row > div:nth-child(n + 2) {
  flex: 1;
}

.formRow {
  display: flex;
  align-items: center;
}

.formRow > *:not(:last-child) {
  margin-right: 8px;
}

.formRow input {
  width: 100%;
}

.formRow :global(.form-item) label {
  font-size: 12px;
}

.name {
  composes: form-item from global;
  flex: 1;
}

.price {
  composes: form-item from global;
  flex: 0.5;
}

.desc {
  composes: form-item from global;
  flex: 2;
}

.close {
  composes: secondary button from global;
  padding: 0;
}
