@import "styles/_variables.scss";

.position {
  display: flex;
}

.position button {
  width: 32px;
  height: 32px;
  margin-top: 25px;
}

.position > :not(:last-child) {
  margin-right: 4px !important;
  flex: 1;
}

.picker {
  composes: clickable secondary-invert border from global;
}

.picker:global(.active) {
  color: #00ff00;
}
