@import "styles/_variables.scss";

.root {
  composes: modal from global;
  margin: calc(50vh - max(200px, 20vh) / 2) auto;
  max-width: 500px;
  width: 40vw;
  height: min-content;
}

.root > h2 {
  margin-bottom: 16px;
}

.root > label {
  font-size: 14px;
}

.root lottie-player {
  height: 250px;
  margin-bottom: 50px;
}

.options {
  margin-top: 16px;
  margin-bottom: 32px;
}

.options :global(.buttons),
.footer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  justify-content: space-around;
}

.desc {
  margin-top: 8px;
  text-align: center;
  color: #666;
}

.progress {
  margin-top: -100px;
  margin-bottom: 50px;
  text-align: center;
}

.progress p {
  margin: 8px;
}
