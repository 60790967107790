.root {
  composes: modal from global;
  margin: 10vh 10vw;
  max-width: 80vw;
  width: 80vw;
  height: 80vh;
}

.loading {
  margin-top: 10vh;
  height: 60vh;
}
