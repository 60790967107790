@import "styles/_variables.scss";

.body {
  width: 100%;
  min-height: 100vh;
  padding: 72px 16px 16px 16px;
  border-radius: 8px 0 0 8px;
  margin: 0;
  background-color: #f5f5f5;
}

.section {
  padding: 0;
  border-radius: 8px;
}

.back {
  composes: secondary button border from global;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back svg {
  height: 12px;
  margin-left: 0;
}

.close {
  composes: error-invert border button border from global;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close svg {
  height: 12px;
  margin-left: 0;
}

.title {
  display: flex;
  align-items: center;
}

.title h2 {
  margin-right: 8px;
}

.emptyState {
  margin-top: 20vh;
  text-align: center;
}

.emptyState > h3 {
  margin-bottom: 16px;
}

.buttons {
  display: inline-flex;
}

.buttons button:not(:last-child) {
  margin-right: 4px;
}