@import "styles/_variables.scss";

.theme {
  composes: clickable from global;
  cursor: pointer;
  position: relative;
  width: fit-content;
  height: fit-content;
}

.theme > svg {
  box-shadow: 0 0 5px 0 #999;
}

.switchIcon {
  pointer-events: none;
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: $appbar-bg-color-1;
  color: $appbar-color-1;
  border-radius: 15px;
  transition: all ease 250ms;
}

.switchIcon svg {
  width: 14px;
  transform: translate(0px, 2px);
}

.theme:hover .switchIcon {
  opacity: 0.5;
}