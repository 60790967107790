.download {
  composes: button primary from global;
  padding-right: 20px;
}

.download svg,
.view svg {
  margin-top: 1px;
}

